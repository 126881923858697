import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../../components/SEO';
import { SignupButtonWithChoice } from '../../components/SignupButton';
import PageHead from '../../components/PageHead';
import {
  FeatureGrid,
  HeatmapsCost,
  HeatmapsSpeed,
  HeatmapsClicksAndConversions
} from '../../components/FeatureGrid';
import { VIDEOS, VimeoVideo } from '../../components/VimeoVideo';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/heatmaps/"
          title="Heatmaps for Affiliate Publishers"
          description="Visualize both clicks and conversions on these affiliate heatmaps."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '630px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Heatmaps for affiliates that show you which buttons, links, and
            creatives actually converted
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            Experiment with confidence by identifying which buttons, links,
            copy, and on-page elements are responsible for your affiliate
            conversions.
          </Typography>
          <div style={{ maxWidth: '800px', margin: '0 auto 36px' }}>
            <VimeoVideo videoId={VIDEOS.HEATMAPS} />
          </div>
          <div style={{ textAlign: 'center', margin: '48px auto 0' }}>
            <SignupButtonWithChoice text="Start recording your heatmaps" />
          </div>
        </PageHead>
        <div style={{ maxWidth: '900px', margin: '96px auto 64px' }}>
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 700
            }}
          >
            You might have a thousand-dollar button on your website.
          </Typography>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            You just don't know it yet.
          </Typography>
        </div>

        <FeatureGrid>
          <HeatmapsCost />
          <HeatmapsClicksAndConversions />
          <HeatmapsSpeed />
        </FeatureGrid>
      </div>
    </MainLayout>
  );
};
